import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import useApi from '../../hooks/useApi';
import { setAlbums, deleteAlbum } from '../../modules/albums';
import AlbumGrid from './AlbumGrid';
import AppLayout from '../../components/ui/AppLayout';

function Start() {
  const [isLoading, setIsLoading] = useState(false);
  const albums = useSelector(state => state.albums.albums);
  const api = useApi();

  const dispatch = useDispatch();

  useEffectOnce(() => {
    (async () => {
      setIsLoading(true);
      const { data } = await api.get('/albums');
      dispatch(setAlbums(data.albums));
      setIsLoading(false);
    })();
  });

  const handleDestroy = async id => {
    const result = window.confirm('Are you sure?');

    if (result) {
      setIsLoading(true);
      await api.delete(`/albums/${id}`);
      dispatch(deleteAlbum(id));
      setIsLoading(false);
    }
  };

  return (
    <AppLayout>
      <Row className="my-5">
        <Col sm={12} md={6} lg={6}>
          <h1 className="font-weight-bold">Meine Alben</h1>
          <p>Hier findest du die von dir erstellen Alben in der Übersicht.</p>
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          <Link
            className="new-album-link qa-new-album-wizard-button"
            to="/album/new"
            as={Button}
          >
            <Button variant="primary">Neues Album erstellen</Button>
          </Link>
        </Col>
      </Row>
      <AlbumGrid
        albums={albums}
        isLoading={isLoading}
        onDestroy={handleDestroy}
      />
    </AppLayout>
  );
}

export default Start;
