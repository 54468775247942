import React, { useState, useRef } from 'react';
import { bool, func, arrayOf } from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useClickAway } from 'react-use';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';

import ImageItem from '../ImageItem';
import { ImageObjectShape } from '../../shapes';
import { deleteImage } from '../../../actions/images';
import useApi from '../../../hooks/useApi';
import ImagesSidebarMenu from './ImagesSidebarMenu';
import { getSelectedElementIds } from '../../../selectors/legacy';
import useAnalytics from '../../../containers/app/useAnalytics';
import useScopedImages from './useScopedImages';

function ImagesPanel({ applyImage, images, isOver, dropRef }) {
  const [open, setOpen] = useState(true);
  const [filter, setFilter] = useState('');
  const [showAlert, setShowAlert] = useState(true);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const imageRef = useRef(null);
  const api = useApi();
  const analytics = useAnalytics();

  const dispatch = useDispatch();
  const selectedElementIds = useSelector(getSelectedElementIds);

  const handleImageClick = itemId => {
    setSelectedImageId(selectedImageId !== itemId ? itemId : null);
    applyImage(itemId);

    selectedElementIds.forEach(id =>
      analytics.track('Image Replaced', {
        id,
        imageId: itemId,
        drop: false,
      })
    );
  };

  const handleImageDestroy = async () => {
    await api.delete(`/images/${selectedImageId}`);
    dispatch(deleteImage(selectedImageId));
    setSelectedImageId(null);
  };

  useClickAway(imageRef, () => setSelectedImageId(null), ['click']);

  let { images: filteredImages } = useScopedImages(images);
  if (filter) {
    filteredImages = filteredImages.filter(
      image => image.blob.filename.indexOf(filter) !== -1
    );
  }

  return (
    <>
      <ImagesSidebarMenu
        filter={filter}
        setFilter={setFilter}
        itemsCount={filteredImages.length}
        hasSelection={!!selectedImageId}
        onDestroy={handleImageDestroy}
      />
      <Alert
        variant="light"
        dismissible
        show={showAlert}
        onClose={() => setShowAlert(false)}
        className="border mt-2"
      >
        In diesem Bereich kannst du Hintergrundfotos für dein Album hochladen.
        Fotos für deine Sticker erstellst und verwaltest du über den{' '}
        <Link to="stickers">Sticker-Tab</Link>.
      </Alert>
      <div
        ref={dropRef}
        className={`sidebar-panel scrollable qa-image-content ${
          isOver ? 'dnd-hover' : ''
        }`}
      >
        <div
          className="d-flex justify-content-between align-items-center qa-folder-images"
          onClick={() => setOpen(!open)}
        >
          <span className="py-4 w-100">
            <h6 className="m-0 qa-images-folder-title font-weight-light">{`Bilder (${filteredImages.length})`}</h6>
          </span>
          <span className="ml-2">
            <Icon name={open ? 'angle up' : 'angle right'} />
          </span>
        </div>
        <div className="images-grid">
          {open &&
            filteredImages.map(item => (
              <ImageItem
                key={item.id}
                className={`${selectedImageId === item.id ? 'active' : ''}`}
                image={item.id}
                label={item.blob.filename}
                onClick={() => handleImageClick(item.id)}
                parentRef={selectedImageId === item.id ? imageRef : null}
              />
            ))}
        </div>
      </div>
    </>
  );
}

ImagesPanel.defaultProps = {
  dropRef: () => {},
};

ImagesPanel.propTypes = {
  applyImage: func.isRequired,
  images: arrayOf(ImageObjectShape).isRequired,
  isOver: bool.isRequired,
  dropRef: func,
};

export default ImagesPanel;
